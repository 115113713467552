.treeTest-box {
  display: flex;
  width: 100%;
  height: 100%;
}
.treeTest-box1 {
  display: flex;
  width: 100%;
}
.treeTest-box2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #363636;
  width: 100%;
  height: 100%;
  border: 2px solid #333333;
  overflow: auto;
}
.treeTest-box2-node-content {
  display: flex;
}
.treeTest-box2-node-content .controller {
  position: absolute;
  left: 0;
  width: 30px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.treeTest-box2 .ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  display: none;
}

.treeTest-box2 .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  margin-left: 32px;
}
.ant-tree {
  background: #363636 !important;
  width: 100%;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: flex-start !important;
}
.ant-tree-title {
  color: #dad6d6;
  margin-left: 5px !important;
}
.treeTest-box2 .ant-tree .ant-tree-node-content-wrapper {
  position: static;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #363636;
}
.input-tree {
  touch-action: manipulation;
  background-color: #333;
  border: 1px solid #000;
}

.ant-tree.ant-tree-directory .ant-tree-treenode {
  width: 100%;
}

.ant-tree.ant-tree-block-node
  .ant-tree-list-holder-inner
  .ant-tree-node-content-wrapper {
  display: flex;
  flex-direction: row;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: rgba(42, 151, 253, 0.2);
}
.ant-tree-switcher {
  width: 10px !important;
}
span.ant-tree-iconEle.ant-tree-icon__customize {
  color: #dad6d6;
}

.ant-tree.ant-tree-directory .ant-tree-treenode:not(.ant-tree-treenode-selected):hover::before {
  background: rgba(70, 69, 69, 0.3);
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  width: auto !important;
}
span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open {
  display: flex;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: rgba(70, 69, 69, 0.3);
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #000 !important;
}
span.anticon.anticon-play-circle {
  margin: 0 5px !important;
}
