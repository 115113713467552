* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

header {
  height: 40px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: rgb(61, 61, 61);
  background: rgb(216, 216, 216);
  /* border-top: 3px solid #000; */
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  height: 40px;
  position: absolute;
  left: 0;
}

.logo {
  text-decoration: none;
  font-size: 22px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 2px 10px;

  display: flex;
  justify-content: space-between;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: rgba(57, 71, 102, 0.5);
}

/* .arrow::after {
  content: '';
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
} */

.dropdown {
  position: absolute;
  /* right: 0; */
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;

  background-color: rgb(240, 240, 240);

  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0 !important;
}
