.treeTest-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #363636;
  width: 100%;
  height: 100%;
  /* border: 2px solid #333333; */
}
.ant-tree {
  background: #363636 !important;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: flex-start !important;
}
.ant-tree-title {
  color: #dad6d6;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #363636;
}
.input-tree2 {
  touch-action: manipulation;
  background-color: #333;
  border: 1px solid #000;
  box-shadow: 10 10px 10px #333;
}
