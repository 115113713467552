.loading-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.loading-circle {
    width: 100px;
    height: 100px;
    border-radius: 40%;
    border: 5px #ffffff solid;
    border-right-color: #87CEEB;
    animation: loading 1s linear infinite;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}