.installation{}

.installation :global {
    .header-operation .pos-btn {
        width: 120px;
        color: #000;
        background: #fff;
    }   
    .add-btn {
        width: 120px;
    }

    .installation-card {
        background: #fff;
        width: 210px;
        height: 190px;
        box-shadow: 1px 2px 2px #a5a6a6;
        padding: 20px 20px;
        position: relative;
        .card-pic {
            width: 50px;
            height: 50px;
            background: pink;
        }
        .card-time {
            margin-top: 6px;
            font-size: 18px;
        }
        .card-icon {
            position: absolute;
            right: 16px;
            top: 16px;
            font-size: 28px;
        }
    }
}