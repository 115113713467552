* {
  margin: 0px;
  padding: 0px;
  user-select: none;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
}

.lianxian {
  width: 100%;
  height: 100%;
}

.box2 {
  display: flex;
  width: 100%;
  height: 100%;
}

.tansfrom {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.list {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 200px;
  height: 98%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  user-select: none;
  background-color: #363636;
  border-right: 1px black solid;
  z-index: 100;
}

.list div {
  width: 100%;
  /* text-align: center; */
  height: 30px;
  line-height: 30px;
  padding-left: 12px;
  color: #dad6d6;
  margin-top: -1.5px;
  /* margin-bottom: -1px; */
  border-bottom: 1px solid rgb(88 88 88 / 30%);
  border-top: 1px solid #2a2e30;
  font-size: 10px;
  letter-spacing: 1px;
}

.listHeader {
  margin-top: 5px !important;
  font-weight: 600 !important;
  color: aliceblue !important;
}

.dragable:hover {
  background-color: #191c1d;
  color: #fff;
}

.panel {
  flex: 1;
  background-color: #363636;
  position: relative;
  width: 4000px;
  height: 4000px;
}

.boxInput {
  padding: 6px 7px;
  background: #1e1e1e;
  margin-left: 10px;
  margin-top: 5px;
  width: 150px;
  height: 10px;
  border: 0px;
  font-size: 10px;
  border-radius: 4px;
}

.list .ant-input-affix-wrapper {
  width: 90%;
  margin-left: 5%;
  margin-top: 5px;
}

.boxInput:focus {
  outline: none;
  /* outline-color: 0px;
  outline-style: 0px;
  outline-width: 0px; */
}

.lightCode {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
  position: absolute !important;
  padding: 4px;
  border: 1px solid transparent;
  min-width: 100px;
  background: #1f2324;
  box-sizing: content-box;
  transform-origin: bottom right;
}

.lightCode .head {
  color: #aaa;
  text-align: center;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
  font-size: 10px;
}

.options div {
  color: #555;
  line-height: 10px;
}

.options::after {
  content: '';
  display: block;
  clear: both;
}

.inputs,
.outputs {
  min-width: 40px;
}

.inputs,
.center {
  float: left;
}

.center .black {
  width: 20px;
  height: 20px;
  border: 1px solid #555;
  background-color: #000;
}

.outputs {
  float: right;
}

.outputs div {
  text-align: right;
}

.field {
  /* padding: 4px; */
  vertical-align: middle;
  font-size: 10px;
  cursor: pointer;
}

.inner-field {
  display: inline-block;
  min-height: 14px;
  background: transparent;
  vertical-align: middle;
}

.dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #555;
}

.inputs .dot {
  margin-right: 4px;
}

.outputs .dot {
  margin-left: 4px;
}

.field:hover .inner-field {
  color: #fff;
}

.field:hover .dot {
  background-color: #fff;
}

.field-hover .inner-field {
  color: #fff;
}

.field-hover .dot {
  background-color: #fff;
}


.field-hover:hover .inner-field {
  color: #ff7800;
}

.field-hover:hover .dot {
  background-color: #ff7800;
}