

// project 选项卡
.project {
    height: 80vh;
    overflow: auto;
}

.project :global {
    .project-header {
        width: 100%;
        height: 30vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .card-box {
            width: 32%;
            height: 100%;
            background: #fff;
            box-shadow: 2px 2px 6px #a5a6a6;
            overflow: hidden;
            .card-pic {
                width: 100%;
                height: 70%;
                background: pink;
            }
            .card-info {
                padding: 20px 10px;
                div:first-child {
                    font-weight: 600;
                }
                div:last-child {
                    color: #757575;
                }
            }
        }
    }

    .project-list {
        margin-top: 30px;
        width: 100%;
        background: #fff;
        box-shadow: 2px 2px 6px #9c9c9d;
        .item {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #d5d5d6;
            padding: 20px 16px;            
            .item-pic {
                width: 50px;
                height: 50px;
                background: pink;
                margin-right: 20px;
            }
            .item-info {
                div:first-child {
                    font-weight: 600;
                }
                div:last-child {
                    color: #757575;
                }
            }
        }

    }
}