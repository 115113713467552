.scene-head {
  width: 100%;
  height: 28px;
  background-color: #3c3c3c;
  padding: 3px;
  display: flex;
  justify-content: space-between;
}

.select-menu {
  display: flex;
}

.select-menu .ant-dropdown-trigger {
  background-color: rgb(88, 88, 88);
  padding: 0 2px;
}

.select-menu .ant-dropdown-trigger:hover {
  background-color: rgb(103, 103, 103);
}

.tool-icon {
  color: rgb(88, 88, 88);
}

.scene-check {
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  background-color: transparent;
  color: transparent;
  margin-right: 4px;
}

.ant-dropdown-menu-item {
  padding: 1px 4px;
}

.scene-checked {
  background-color: rgba(145, 201, 247, 0.5);
  color: #000;
}

.list-checked {
  color: rgba(191, 191, 191, 0.9);
}

.scene-tool {
  display: flex;
}

.tool-icon {
  padding-top: 4px;
}

.select-box {
  min-width: 37px;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: rgb(88, 88, 88);
  color: rgba(191, 191, 191, 0.9);
  margin-right: 6px;
}

.select-box:active {
  background-color: rgb(70, 96, 124);
}

.select-disabled {
  background-color: rgb(74, 74, 74);
  color: rgb(135, 135, 135);
}

.select-item {
  background-color: rgb(70, 96, 124);
  color: #fff;
}

.select-icon {
  padding: 0 4px;
}

.scene-panel {
  position: absolute;
  min-width: 370px;
  padding: 4px;
  background-color: #3c3c3c;
  border: 1px solid #000;
  top: 22px;
  left: 0;
  z-index: 100;
  color: rgba(191, 191, 191, 0.9);
}

.scene-panel-title {
  line-height: 16px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.scene-panel-ul {
  list-style: none;
}

.scene-panel-li {
  font-size: 12px;
}

.scene-panel-li:hover {
  background-color: rgb(62, 95, 150);
}

.panel-gizmos {
  max-height: 600px;
  overflow: auto;
  padding: 4px;
  border-top: 1px solid #000;
}

.panel-gizmos table th {
  padding: 2px;
  font-size: 12px;
  font-weight: 300;
}

.panel-gizmos table td {
  font-size: 14px;
  text-align: center;
}

.panel-gizmos table td:first-of-type {
  text-align: left;
}