.layout-box {
  display: flex;
  flex-direction: column;
  background: #363636;
  width: 100%;
  height: 100%;
  border: 2px solid #333333;
  overflow: hidden;
}
.layout-content-1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.layout-content-left {
  display: flex;
}
.plusO {
  color: #c4c4c4;
}
.caretD {
  color: #c4c4c4;
}
.layout-content-right {
  display: flex;
}
.searchP {
  width: 10px;
  height: 10px;
}
.right-3 {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}
.macC {
  color: #c4c4c4;
}
.slidersF {
  color: #c4c4c4;
}
.tagF {
  color: #c4c4c4;
}
.profileF {
  color: #c4c4c4;
}
.eyeI {
  color: #c4c4c4;
}
.right-5 {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}
.layout-content-2 {
  display: flex;
  flex-direction: row;
  background: #363636;
  width: 100%;
  height: 100%;
  border: 2px solid #333333;
  overflow: hidden;
}
.layout-box-1 {
  display: flex;
  flex-direction: row;
  background: #363636;
  width: 100%;
  height: 100%;
  border: 2px solid #333333;
  overflow: auto;
}
.lay-0 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.layout-box-2 {
  display: flex;
  flex-direction: column;
  background: #363636;
  width: 100%;
  height: 100%;
  border: 2px solid #333333;
  overflow: auto;
}
.lay-1 {
  width: 100%;
}
.lay-2 {
  width: 100%;
  height: 90%;
  border-top: 1px solid rgb(5, 5, 5);
  border-bottom: 1px solid rgb(5, 5, 5);
}
.lay-3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 5px;
}
.lay-3 .slider {
  width: 100px;
  margin-left: 70%;
}

.ant-slider-rail {
  background-color: #969494 !important;
}
.ant-slider-track {
  background-color: #706f6f !important;
}
.ant-input-affix-wrapper {
  margin-top: 2px;
  height: 18px;
  background-color: #1b1b1b;
  border: 1px solid #000;
}
span.anticon.anticon-mac-command {
  width: 15px;
  height: 15px;
  margin: 0 5px;
}
span.anticon.anticon-sliders.slidersF {
  margin: 0 5px;
}
.span.anticon.anticon-sliders {
  margin: 0 5px;
}
span.anticon.anticon-tag {
  margin: 0 5px;
}
span.anticon.anticon-profile {
  margin: 0 5px;
}
span.anticon.anticon-eye-invisible {
  margin: 0 5px;
}
.ant-breadcrumb a {
  color: rgba(199, 199, 199, 0.85);
}
.ant-breadcrumb a:hover {
  color: rgba(199, 199, 199, 0.85);
}
.ant-breadcrumb li:last-child a {
  font-weight: 400;
  color: rgba(252, 252, 252, 0.85);
}
span.ant-breadcrumb-separator {
  color: rgba(114, 113, 113, 0.85);
}
.right-6 {
  color: rgba(252, 252, 252, 0.85);
}
