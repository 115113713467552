.modalColor-box {
  width: 100%;
  height: 100%;
}
#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}
button.ant-btn.ant-btn-default {
  color: #fff;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #363636 !important;
}
.ant-layout-sider-trigger {
  position: absolute !important;
}
.ant-layout-sider {
  background: #363636 !important;
}
.ant-layout-sider-trigger {
  background: #1b1b1b !important;
}
aside.ant-layout-sider.ant-layout-sider-dark {
  max-width: 190px !important;
  min-width: 130px !important;
  width: 130px !important;
}
/* .ant-layout-sider-trigger {
  width: 130px !important;
} */
main.ant-layout-content {
  margin: 0 !important;
}
li.ant-menu-item {
  padding-left: 10px !important;
}
.modalColor-box aside.ant-layout-sider.ant-layout-sider-dark {
  flex: 0 0 130px !important;
}
