.console-box {
  display: flex;
  flex-direction: column;
  background-color: #363636;
  width: 100%;
  height: 100%;
}
a {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  -webkit-user-select: none;

  -moz-user-focus: none;

  -moz-user-select: none;
}
.console-content1 {
  display: flex;
  justify-content: space-between;
}
/* .dd {
  width: 115%;
  height: 140%;
  margin-left: -5px;
} */

.console-content1-box1 {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: clip;
}
.console-content2 {
  height: 60%;
  overflow: auto;
  position: relative;
}

.infinite-list-phantom {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: -1;
}
.infinite-list {
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}

.console-content3 {
  display: flex;
  flex-direction: column;
}
.console-content1-box1 button.ant-btn-default {
  color: rgba(190, 190, 190);
  font-size: 10px;
}
.console-content1-box1 .ant-btn {
  padding: 0 5px;
  line-height: -1px;
}
.console-content1-box1 button.ant-btn.ant-btn-default {
  height: 23px;
}
.console-content1-box1
  button.ant-btn.ant-btn-default.ant-btn-icon-only.ant-dropdown-trigger {
  width: 23px;
  height: 25px;
  /* border-left: 1px solid #1b1b1b; */
}
.collapse-title {
  font-size: 10px;
}
.console-content1-box1 a.ant-dropdown-trigger.collapse-title {
  border-left: 1px solid #1b1b1b;
  border-right: 1px solid #1b1b1b;
  padding: 4px 5px;
}
.collapse-title2 {
  font-size: 10px;
  padding: 4px 5px;
  border-right: 1px solid #1b1b1b;
}
.collapse-title3 {
  font-size: 10px;
  padding: 4px 5px;
  border-right: 1px solid #1b1b1b;
}

.console-content1-box2 {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.console-input {
  border-radius: 5px;
  background-color: #1b1b1b !important;
}
.console-icon {
  flex-wrap: nowrap;
  align-items: center;
  margin-left: 5px;
  /* margin-right: 5px; */
  width: 90px;
  display: flex;
  justify-content: space-evenly;

  /* height: 23px; */
  overflow: hidden;
}
.iconbox {
  height: 30px;
}

.Asize{
 font-size: 8px;
}
.iconbox span {
  margin-left: 3.5px;
}

.exclamationC {
  color: rgb(211, 210, 210);
}
.console-content1-box2
  span.ant-input-affix-wrapper.console-input.ant-input-affix-wrapper-sm {
  margin-top: 3px;
}
.console-box .ant-checkbox-inner {
  height: 30px;
  background-color: #fff;
}
.console-box .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2e2e2e;
}
label.ant-checkbox-wrapper.ant-dropdown-menu-item-icon {
  margin-right: 5px;
}
.ant-dropdown-menu {
  padding: 0;
}
.console-box li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  padding: 0px 8px;
  background-color: #2b2b2b;
  color: rgb(211, 210, 210);
  border: 1px solid #1b1b1b;
}
.console-divider {
  height: 100%;
  border-left: 1px solid #1b1b1b;
}
.console-box .console-content1-box1 li.ant-dropdown-menu-item {
  padding: 3px 10px;
}
.warningF {
  /* width: 25px; */
  /* height: 25px; */
  color: #f5b502;
}
.StopF{
  color: #fd0101
}
.console-content2 .ant-list-item {
  padding: 3px 0;
  border: 0;
  height: 45px;
  overflow: hidden;
  align-items: stretch;
}
.console-content2 .ant-list-item-meta-content {
  margin-top: 6px;
}
.console-content2 .ant-list-item-meta-avatar {
  margin-right: 0;
}
.console-content2 .ant-list-item-meta-title > a {
  color: rgb(213 213 213 / 85%);
}
.console-content2 .ant-avatar {
  font-size: 22px;
}
.console-content2 .ant-list-item-meta-title {
  font-size: 10px;
}
.console-content3-top {
}
.console-content3-top-font {
  font-size: 14px;
  color:rgb(211, 210, 210)
}
.console-icon .ant-btn {
  padding: 0 5px;
}
.console-icon .ant-divider-vertical {
  margin: 0 5px;
}
