.mainCom {
    padding: 30px 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.mainCom :global {

    // Main 组件的 头部
    .main-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    // Main 组件的 内容区域
    .main-content {
        padding: 10px;
    }

    // Main 组件的 标题样式
    .main-title {
        font-size: 30px;
        box-sizing: border-box;
    }

    // Main 组件的 头部控制按钮
    .header-operation {
        width: 260px;
        display: flex;
        justify-content: space-around;
    }
    .header-operation button {
        height: 30px;
        vertical-align: middle;
        border: none;
        background: #2297f3;
        color: #fff;
        border-radius: 2px;
        box-shadow: 3px 2px 10px #c4c5c6;
    }
}