.box {
    height: 100%;
    width: 100%;
    background: #f2f3f4;
    overflow: hidden;
}

.box :global {
 
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: none;
        border: 1px solid #d9d9d9;
    }
}